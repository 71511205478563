import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import DrawerAppBar from '../AppBar'
import logo from '../../a.png';
import logo1 from '../../ab.png';
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker
} from "react-simple-maps";
import { ContactsOutlined } from '@mui/icons-material';
import ReportService from '../../services/report.service';
import Swal from 'sweetalert2';
import DataService from '../../services/data.service';
import { useAuth } from '../../hooks/useAuth';
const Home = () => {
  const [open, setOpen] = React.useState(false);
  const auth = useAuth();
  var facility = localStorage.getItem('facility')
  let a = []
  const geoUrl =
    "";
  const [rows, setrows] = useState({});
  const [one, setOen] = useState({});
  useEffect(() => {
    localStorage.getItem('role') != 'reception' &&    localStorage.getItem('role') != 'doctor'&& ReportService.MAP()
      .then((data) => data)
      .then((data) => setrows(data))

      .catch(function (err) {
        if (!err?.response) {
        } else if (err.response?.status === 400) {
      
        } else if (err.response?.status === 401 || err.response?.status === 422) {

          DataService.GetRefresh().then(()=>window.location.reload()
          ).catch(function (err) {
            if (!err?.response) {
              Msg('No Server Response');
              localStorage.clear()
            
            } else if (err.response?.status === 400) {
              Msg('error');
           
            } else if (err.response?.status === 401 || err.response?.status === 422) {
              localStorage.clear()
            
            
          } 
       
          
          
     })
        
        }
      });
    // setloading(true)

  }, [])


  if (rows.length != undefined) {
    for (let index = 0; index < rows.length; index++) {
      if (rows[index].lang != null && rows[index].loat != null&& rows[index].total != 0) {
        a.push({
          markerOffset: -10,
          name: rows[index].city,
          total: rows[index].total,
          coordinates: [rows[index].lang, rows[index].loat],
         data: rows[index].data
      
        }

        )
      }


    }
  }


    

  function Msg(params, total) {
    Swal.fire({
      title: params,
      html: total + "   عدد المرضي الاجمالي ",
    });
    return 0;
  }
  function openDialog(total,name,data) {
    setOpen(true)
    setOen({
      name:name,
      total:total,
      data:data,
      
    })
  }
  console.log(one)
  return (
    <div>
      <DrawerAppBar />
      <Dialog open={open} fullWidth={true}
    maxWidth={'md'} >
    <DialogTitle ><h5 inputProps={{ min: 0, style: { textAlign: 'right' } }}>
      {one.name} </h5></DialogTitle>

    <DialogContent>
{one.data  != undefined &&
            (one.data).map((result, index) => (
              <h5> 
                     {result.name}  = {result.quantity}
                     </h5>
             ))}
            <br/>
            <h5>
أجمالي عدد المرضي  :{one.total} 
            </h5>
   
    </DialogContent>
   
    <div className='l'>


      <Button onClick={() => setOpen(false)}>الغاء</Button>
    </div>



  </Dialog>
     
   
<div className="center1">
<div className="bg-20">

<img src={logo1} alt="Logo" width={'220px'} className=""/>
</div>
      

  <div className="">
  <h1 className="R22">   
        <span className='' style={{color:'#000000'}}> 
الحكومة الليبية - وزارة الصحة 
        <br />

       منظومة حصر مرضى الأورام
       
  </span>
      
        </h1>
  </div>

  <div className="bg-100">

<img src={logo} alt="Logo" width={'125px'} className=""/>
</div>
</div>
    {
      localStorage.getItem('role') != 'reception' && localStorage.getItem('role') != 'doctor' ?
      <div className='imglibya'>
   
      <ComposableMap
        projection="geoAzimuthalEqualArea"
        projectionConfig={{
          rotate: [0, 30, 0],
          scale: 300
        }}
      >

        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                fill="#EAEAEC"
                stroke="#D6D6DA"
              />
            ))
          }
          {/* Msg(name, total) */}
        </Geographies>
        {a.map(({ name, coordinates, markerOffset, total,data }) => (
         
          <Marker key={name} coordinates={coordinates} onClick={() => openDialog(total,name,data)}>
          <circle r={3}    y={markerOffset} fill="#F00" stroke="#fff" strokeWidth={2} />
            <text
              textAnchor="middle"
              y={markerOffset}
              style={{ fontFamily: "system-ui", fill: "#5D5A6D", fontSize: '0.555em' }}

            >

              {name}

            </text>
          </Marker>
        ))}
      </ComposableMap>
     
      </div>:null
    }
      
       

    </div>
  )
}

export default Home
