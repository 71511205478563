import { useEffect, useState } from "react";
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import Typography from "@mui/material/Typography";
import Cookies from "universal-cookie";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
} from "@mui/material";
import $, { map } from "jquery";
import Swal from "sweetalert2";
import DataService from "../../../services/data.service";
import DrawerAppBar from "../../AppBar";
import api from "../../../api/axios";
import MedicinesService from "../../../services/medicines.service";

const Medicines = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  console.log(id);
  let tp = id.split("&")[1];
  id = id.split("&")[0];

  const [isloading2, setisloading2] = useState(true);
  const [isloading, setisloading] = useState(false);
  //isLoading
  const [isLoading, setisLoading] = useState(false);
  const [name, setname] = useState("أضافه");
  const [Uuid, setUuid] = useState(null);
  const [medicine, setmedicine] = useState([]);
  const [Check, setCheck] = useState([]);

  const [tableData2, setTableData2] = useState([]);
  const [formInputData2, setformInputData2] = useState({
    uuid: null,
    quantity: 1,
    name: null,
    dose: null,
    frequency: null,
    roa: null,
    unit: null,
  });
  const handleChangeTable2 = (evnt) => {
    var value = $("#" + evnt.target.name).val();
    var uuidvalue;
    if (evnt.target.name === "name") {
      uuidvalue = $('#medicinelist [value="' + value + '"]').data("value");
      console.log(uuidvalue);

      var newInput = (data) => ({
        ...data,
        [evnt.target.name]: evnt.target.value,
        uuid: uuidvalue,
      });

      if (uuidvalue != undefined) {
        setformInputData2(newInput);
        setCheck({ ...Check, [evnt.target.name]: true });
        if (
          formInputData2.uuid != undefined &&
          formInputData2.quantity != undefined &&
          formInputData2.name != undefined &&
          formInputData2.dose != undefined &&
          formInputData2.frequency != undefined &&
          formInputData2.roa != undefined
        ) {
          setisloading2(false);
        }
      } else {
        setCheck({ ...Check, [evnt.target.name]: false });
      }
    }
    if (evnt.target.name === "quantity" || evnt.target.name === "dose") {
      if (evnt.target.value <= 0) {
        evnt.target.value = 1;
      }
      var newInput = (data) => ({
        ...data,
        [evnt.target.name]: evnt.target.value,
      });
      setformInputData2(newInput);
      if (
        formInputData2.uuid != undefined &&
        formInputData2.quantity != undefined &&
        formInputData2.name != undefined &&
        formInputData2.dose != undefined &&
        formInputData2.frequency != undefined &&
        formInputData2.roa != undefined
      ) {
        setisloading2(false);
      }
    }
    if (
      evnt.target.name != "quantity" ||
      evnt.target.name != "name" ||
      evnt.target.name != "dose"
    ) {
      var newInput = (data) => ({
        ...data,
        [evnt.target.name]: evnt.target.value,
      });
      setformInputData2(newInput);
      if (
        formInputData2.uuid != undefined &&
        formInputData2.quantity != undefined &&
        formInputData2.name != undefined &&
        formInputData2.dose != undefined &&
        formInputData2.frequency != undefined &&
        formInputData2.roa != undefined
      ) {
        setisloading2(false);
      }
    }
  };

  function Msg(params) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: params + "!",
    });
    return 0;
  }
  const handleSubmit = (evnt) => {
    setisloading(true);
    setisLoading(true);
    evnt.preventDefault();
    console.log(name);
    console.log(formInputData2);
    const checkEmptyInput = !Object.values(formInputData2).every(
      (res) => res === ""
    );
    if (checkEmptyInput) {
      const newData = (data) => [...data, formInputData2];
      setTableData2(newData);
      const emptyInput = {
        uuid: undefined,
        quantity: undefined,
        name: undefined,
        dose: undefined,
        frequency: undefined,
        roa: undefined,
      };
      var tokens = localStorage.getItem("accessToken");

      if (name == "تعديل") {
        // console.log( formInputData2)
        api
          .put(
            `/patient/medicine?uuid=` + Uuid,
            {
              medicine: formInputData2.medicine_uuid,
              quantity: formInputData2.quantity,
              dose: formInputData2.dose,
              frequency: formInputData2.frequency,
              roa: formInputData2.roa,
              unit: formInputData2.unit,

              iscircled: false,
            },
            {
              headers: {
                Authorization: `Bearer ${tokens}`,
              },
            }
          )
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: "success...",
              text: "تم تعديل المريض",
            });
          })
          .catch(function (error) {
            if (error) {
              console.log(error.response);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.response.data.error + "!",
              });
              return 0;
            }
            if (!error.status) {
              console.log(error.response);
              Swal.fire({
                icon: "error",
                title: "NetWork...",
                text: "!مشكلة في شبكة ",
              });
              return 0;
            }
          });
      } else {
        api
          .post(
            `/patient/medicine/add`,
            {
              patient: id,
              medicine: formInputData2.uuid,
              quantity: formInputData2.quantity,
              dose: formInputData2.dose,
              frequency: formInputData2.frequency,
              roa: formInputData2.roa,
              unit: formInputData2.unit,
              tp: tp,

              iscircled: false,
            },
            {
              headers: {
                Authorization: `Bearer ${tokens}`,
              },
            }
          )
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: "success...",
              text: "تم اضافه العلاج",
            }).then(() => {
              setisLoading(false);
              window.location.reload();
            });
          })
          .catch(function (error) {
            if (error) {
              console.log(error.response);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.response.data.error + "!",
              });
              return 0;
            }
          });
      }

      setformInputData2(emptyInput);
      document.getElementById("name").value = "";
      document.getElementById("quantity").value = "";
      document.getElementById("dose").value = "";
      document.getElementById("frequency").value = "";
      document.getElementById("roa").value = "";

      setname("أضافه");
    }
  };

  try {
    useEffect(() => {
      DataService.Getmedicines()
        .then((data) => data)
        .then((data) => setmedicine(data))
        .catch(function (err) {
          if (!err?.response) {
            Msg("No Server Response");
          } else if (err.response?.status === 400) {
            Msg("error");
          } else if (
            err.response?.status === 401 ||
            err.response?.status === 422
          ) {
            DataService.GetRefresh().catch(function (err) {
              if (!err?.response) {
                Msg("No Server Response");
              } else if (err.response?.status === 400) {
                Msg("error");
              } else if (
                err.response?.status === 401 ||
                err.response?.status === 422
              ) {
                Msg("error");
                localStorage.clear();
              }
            });
          }
        });
      MedicinesService.GetTPMedicines(id, tp)
        .then((data) => data)
        .then((data) => {
          setTableData2(data);
        });
    }, []);
  } catch (err) {}
  const navItems = [];
  const handleChange = (data) => {
    setUuid(data.uuid);
    const employeesCopy = tableData2.filter(
      (employee) => employee.uuid !== data.uuid
    );
    setisloading(false);
    document.getElementById("name").value = data.medicine_name;
    console.log(data);
    document.getElementById("quantity").value = data.quantity;
    document.getElementById("dose").value = data.dose;
    document.getElementById("frequency").value = data.frequency;
    document.getElementById("roa").value = data.roa;
    document.getElementById("unit").value = data.unit;

    setformInputData2(data);
    var uuidvalue = $('#medicinelist [value="' + data.name + '"]').data(
      "value"
    );

    console.log(uuidvalue);
    setformInputData2({
      ...data,
      uuid: uuidvalue,
    });

    setTableData2(employeesCopy);

    setname("تعديل");
  };

  return (
    <>
      <DrawerAppBar navItems={navItems} />
      {isLoading ? (
        <div className="spinner-container">
          <div className="loading-spinner"></div>{" "}
        </div>
      ) : (
        <div className="App">
          <div className="Home">
            <br></br>
            <br></br>

            <br />

            <Typography className="R">بيانات العلاج</Typography>

            <Typography className="R">الفترة علاجيه : {tp}</Typography>
            <FormInput2
              handleChangeTable2={handleChangeTable2}
              formInputData2={formInputData2}
              handleSubmit={handleSubmit}
              medicine={medicine}
              Check={Check}
              isloading2={false}
              name={name}
            />

            <Box
              sx={{
                height: tableData2.length <= 5 ? 500 : 1000,
                backgroundColor: "primary",
              }}
            >
              <Table2 tableData2={tableData2} handleChange={handleChange} />

              <br></br>
            </Box>
          </div>
        </div>
      )}
    </>
  );
};

export default Medicines;

export const Table2 = ({ tableData2, handleChange }) => {
  console.log(tableData2);
  let a = 0;
  const [open, setOpen] = useState(false);
  const d = [];
  const [Medicine, setMedicine] = useState("");
  const navigate = useNavigate();
  function onSubmit(e) {
    e.preventDefault();

    setOpen(false);
    api
      .post(
        `/patient/medicine/circle/init`,
        {
          days: d,
          medicine: Medicine,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "success...",
          text: "تم تعديل المريض",
        });
      })
      .catch(function (error) {
        if (error) {
          console.log(error.response);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.error + "!",
          });
          return 0;
        }
        if (!error.status) {
          console.log(error.response);
          Swal.fire({
            icon: "error",
            title: "NetWork...",
            text: "!مشكلة في شبكة ",
          });
          return 0;
        }
      });
  }
  function onSubmit4(e,uuid) {
  
    e.preventDefault();

//add pop up to confirm delete
Swal.fire({
  title: 'هل انت متاكد من حذف ؟',
  text: "لن تستطيع استرجاع  بعد الحذف",
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  cancelButtonText:'الغاء',
  confirmButtonText: 'نعم',
}).then((result) => {
  if (result.isConfirmed) {

    api
      .delete(
        `/patient/medicine?uuid=${uuid}`,
        {
       


  

       
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "success...",
          text: "تم حذف الدواء بنجاح",
        }).then(() => {
          window.location.reload();
        });
      })
      .catch(function (error) {
        if (error) {
          console.log(error.response);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.error + "!",
          });
          return 0;
        }
        if (!error.status) {
          console.log(error.response);
          Swal.fire({
            icon: "error",
            title: "NetWork...",
            text: "!مشكلة في شبكة ",
          });
          return 0;
        }
      });
    }
  })

  }
  return (
    <div>
      {/* <Dialog open={open} fullWidth={true} maxWidth={"md"}>
        <DialogTitle>
          <h5 inputProps={{ min: 0, style: { textAlign: "right" } }}>cycle </h5>
        </DialogTitle>
        <form>
          <DialogContent>
            <div>
              {[1, 2, 3, 4, 5].map((y) => {
                return (
                  <div
                    className="flex"
                    style={{
                      height: "50px",
                    }}
                  >
                    {[1, 2, 3, 4, 5, 6].map((i) => {
                      a++;
                      return (
                        <div
                          key={a}
                          style={{
                            width: "120px",
                          }}
                        >
                          <label key={a + 100} style={{}}>
                            d {a <= 9 ? " " + a + "  " : a}
                          </label>
                          <input
                            key={a}
                            type="checkbox"
                            onClick={(e) => {
                              if (e.target.checked) {
                                //push object to array name: e.target.value?
                                d.push({
                                  name: e.target.value,
                                });
                              } else {
                                d.pop({
                                  name: e.target.value,
                                });
                              }

                              console.log(d);
                            }}
                            id={"d" + a}
                            name={"d" + a}
                            value={"d" + a}
                          />
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button type="submit" onClick={(e) => onSubmit(e)}>
              Save
            </Button>

            {/* </div> 
          </DialogActions>
        </form>
      </Dialog> */}
      <table className="table" id="customers">
        <thead>
          <tr>
            <th>
              <h5
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "Center",
                }}
              >
                الرقم
              </h5>
            </th>
            <th>
              <h5
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "Center",
                }}
              >
                الاسم
              </h5>{" "}
            </th>

            <th>
              <h5
                style={{
                  direction: "rtl",
                  color: "white",

                  float: "Center",
                }}
              >
                الوحده/الجرعة
              </h5>
            </th>
            <th>
              <h5
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "Center",
                }}
              >
                طريقة اعطاء العلاج
              </h5>
            </th>
            <th>
              <h5
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "Center",
                }}
              >
                التكرار
                {/* Frequency    */}
              </h5>
            </th>

            <th>
              <h5
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "Center",
                }}
              >
                الكمية
              </h5>
            </th>

            <th>
              <h5
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "Center",
                }}
              >
            تعديل
              </h5>
            </th>
            <th>
              <h5
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "Center",
                }}
              >
           حذف
              </h5>
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData2.map((data, index) => {
            // how to get max index of array in react js
          

 return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data.medicine_name}</td>
                <td>{data.dose + "/" + data.unit}</td>
                <td>{data.roa}</td>
                <td>
                  {data.frequency == "per/cycle" ? (
                    <button
                      onClick={() => {
                        // setMedicine(data.uuid
                        //   )
                        // setOpen(true);
                        navigate("./cycle/"+ data.uuid);
                      }}
                      style={{
                        background: "#1976D2",
                        color: "white",
                        border: "none",
                        width: "100px",
                      }}
                    >
                      {data.frequency}
                    </button>
                  ) : (
                    data.frequency
                  )}
                </td>

                <td>{data.quantity}</td>

                {/* <td>{data.unit}</td> */}

              
                     <td>
                  <button
                    style={{
                      background: "#1976D2",
                      color: "white",
                      border: "none",
                      width: "100px",
                    }}
                    onClick={() => handleChange(data)}
                    className=""
                  >
                    تعديل
                  </button>
                </td>
                <td>
                
                <button
                    style={{
                      background: "red",
                      color: "white",
                      border: "none",
                      width: "100px",
                    }}
         onClick={(e) => onSubmit4(e,data.uuid)}
                    className=""
                  >
                    حذف
                  </button>
               
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
function FormInput2({
  handleChangeTable2,
  medicine,
  Check,
  handleSubmit,
  isloading2,
  name,
}) {
  return (
    <Box
      sx={{
        height: 300,
        backgroundColor: "primary",
      }}
    >
      <div>
        <div className="flex">
          <div className="0">
            <label className="label"> الادوية </label>
            <br></br>
            <input
              list="medicinelist"
              id="name"
              name="name"
              className="input"
              style={{
                margin: "1%",

                textAlign: "end",
                paddingRight: "2%",
                height: "35px",
              }}
              onChange={handleChangeTable2}
            />
            <h6 className="R">
              {Check?.name === false ? "  يرجي  التأكد من  حروف " : ""}
            </h6>
            <datalist id="medicinelist">
              {medicine.map((medicine) => (
                <option
                  key={medicine.uuid}
                  value={medicine.name}
                  data-value={medicine.uuid}
                >
                  {" "}
                </option>
              ))}
            </datalist>
          </div>
          <div className="0">
            <label className="label">طريقة اعطاء الدواء</label>
            <br></br>
            <select
              className="input"
              name="roa"
              id="roa"
              onChange={handleChangeTable2}
            >
              <option value=""> </option>
              <option value="I.V/INF">I.V/INF</option>
              <option value="I.V/Bolus"> I.V/Bolus</option>
              <option value="S.C"> S.C</option>
              <option value="ORAL">ORAL</option>
            </select>
          </div>
        </div>
        <div className="flex">
          <div className="0">
            <label className="label"> الجرعه </label>
            <br></br>
            <input
              className="input"
         
              required
              onChange={handleChangeTable2}
              name="dose"
              id="dose"
            ></input>
          </div>
          <div className="0">
            <label className="label">التكرار</label>
            <br></br>
            <select
              className="input"
              name="frequency"
              id="frequency"
              onChange={handleChangeTable2}
            >
              <option value=""> </option>
          
              <option value="per/Day"> per/Day</option>
              <option value="per/cycle"> per/cycle</option>
            </select>
          </div>
        </div>
        <div className="flex">
          <div className="0">
            <label className="label">الوحده</label>
            <br></br>
            <select
              className="input"
              name="unit"
              id="unit"
              onChange={handleChangeTable2}
            >
              <option value=""> </option>
              <option value="MG/m2"> MG/m2</option>
              <option value="MG/KG">MG/KG</option>
              <option value="IU"> IU</option>
              <option value="mqg"> mqg</option>
            </select>
          </div>
          <div className="0">
            <label className="label"> الكمية الشهرية</label>

            <br></br>
            <input
              className="input"
              type="number"
              required
              onChange={handleChangeTable2}
              name="quantity"
              id="quantity"
            ></input>
            <h6 className="R">
              {Check?.quantity === false ? "يرجي التأكد من عدد الارقام " : ""}
            </h6>
          </div>
        </div>
        <br></br>
        <Button
          variant="contained"
          className="Save-Button"
          disabled={isloading2}
          onClick={handleSubmit}
          style={{
            fontFamily: "Tajawal",
            textAlign: "center",
            minwidth: "100%",
          }}
        >
          {name}
        </Button>
      </div>
    </Box>
  );
}
